import React, { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import BReporter from '../../Media/Icons/BReporter.png';
import { userInfo } from '../../pages/Login/Login';
import reactSelect from 'react-select';
import { User } from '../SinglePostHelper/SinglePostHelper';
import { useUser } from '../../UserContext';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

function Header() {
	const { user, set_user, isAdmin, isLoggedIn, setIsLoggedIn } = useUser();
	const [showMenu, setShowMenu] = useState(false);
	const [Nav, set_Nav] = useState<number>(0);
	const [triggerScroll, setTriggerScroll] = useState(false);
	const navigate = useNavigate();

	const handleLogout = (set_user: React.Dispatch<React.SetStateAction<User | null>>) => {
		var requestOptions = {
			method: 'GET',
			redirect: 'follow' as RequestRedirect,
			credentials: 'include' as RequestCredentials,
		};

		fetch('https://backend.b-reporter.com/auth/logout', requestOptions)
			.then((response) => response.text())
			.then((result) => {
				set_user(null);
				setIsLoggedIn(false);
				setTimeout(() => {
					navigate('/login');
				}, 0);
				console.log('Logged out successfully. ', result);
			})
			.catch((error) => console.log('error', error));
	};
	const handleToggle = () => {
		setShowMenu(!showMenu);
		console.log('click');
	};
	// const toggleMobileNav = () => {
	// 	const navbar = document.getElementById('navbar');
	// 	if (navbar) {
	// 		navbar.classList.toggle('show');
	// 		document.querySelector('.mobile-nav-show')?.classList.toggle('d-none');
	// 		document.querySelector('.mobile-nav-hide')?.classList.toggle('d-none');
	// 	}
	// };
	// const toggleMobileNav = () => {
	// 	const navbar = document.getElementById('navbar');
	// 	const overlay = document.getElementById('overlay');
	// 	if (navbar && overlay) {
	// 		navbar.classList.toggle('show');
	// 		overlay.classList.toggle('show');
	// 		document.querySelector('.mobile-nav-show')?.classList.toggle('d-none');
	// 		document.querySelector('.mobile-nav-hide')?.classList.toggle('d-none');
	// 	}
	// };

	// const closeMobileNav = () => {
	// 	const navbar = document.getElementById('navbar');
	// 	const overlay = document.getElementById('overlay');
	// 	if (navbar && overlay) {
	// 		navbar.classList.remove('show');
	// 		overlay.classList.remove('show');
	// 		document.querySelector('.mobile-nav-show')?.classList.remove('d-none');
	// 		document.querySelector('.mobile-nav-hide')?.classList.add('d-none');
	// 	}
	// };

	useEffect(() => {
		if (triggerScroll) {
			document.getElementsByClassName('dummy-filler')[0].scrollIntoView();
			setTriggerScroll(false); // Reset the trigger
		}
	}, [triggerScroll]);

	useEffect(() => {
		const handleScroll = () => {
			const arr = window.location.href.split('/');
			const nav = document.getElementsByClassName('navv')[0];
			if (arr[arr.length - 1] === 'news') {
				nav.classList.toggle('sticky', true);
			} else {
				nav.classList.toggle('sticky', window.scrollY >= 10);
			}
		};

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	useEffect(() => {
		var arr = window.location.href.split('/');
		if (arr[arr.length - 1] === 'news' || arr[arr.length - 2] === 'post') {
			set_Nav(4);
			var nav = document.getElementsByClassName('navv')[0];
			nav.classList.toggle('sticky', true);
		} else if (arr[arr.length - 1] === 'login') set_Nav(4);
		else if (arr[arr.length - 1] === 'policy') set_Nav(5);
		else if (arr[arr.length - 1] === 'contact') set_Nav(6);
		else if (arr[arr.length - 1] === 'admin') set_Nav(7);
		else if (arr[arr.length - 1] === 'reports') set_Nav(1);

	}, []);

	return (
		<div>
			<header
				id='header'
				className='header grid grid-cols-4 lg:grid lg:grid-cols-8 fixed-top bg-[#022c56] lg:px-20 h-fit'
			>
				<button
					className={`mobile-nav-toggle mobile-nav-show col-span-1 flex justify-center items-center lg:hidden ${showMenu ? 'text-black': 'text-white'}`}
					onClick={handleToggle}
				>
					{showMenu ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faBars} />}
				</button>
				{/* <i className='mobile-nav-toggle mobile-nav-hide d-none'>
					<FontAwesomeIcon icon={faTimes} />
				</i> */}

				<div className='brand col-span-2 lg:flex  justify-center items-center lg:col-span-2 lg:justify-center lg:items-center h-full'>
					<button className='navbar-brand' onClick={() => navigate('/')}>
						<img src={BReporter} className='lg:h-24 lg:w-24 h-20 w-20' />
					</button>
					<h3 className='text-white text-3xl hidden lg:flex'>
						<b>B-Reporter</b>
					</h3>
				</div>

				<nav id='navv' className={`navv lg:col-span-5 lg:flex lg:justify-end lg:items-center  text-zinc-400 hidden`}>
					<div className='m-0 lg:flex lg:justify-end lg:gap-5 w-full text-xl font-medium '>
						<li className='nav-item'>
							<button
								className={`nav-link ${Nav === 0 ? 'active text-white' : ''}`}
								id='nav-link'
								onClick={() => {
									set_Nav(0);
									navigate('/');
									setShowMenu(false);
									setTimeout(() => {
										document.getElementsByClassName('home-scroll-point')[0].scrollIntoView();
									}, 0);
								}}
							>
								Home
							</button>
						</li>
						<li className='nav-item'>
							<button
								className={`nav-link  ${Nav === 2 ? 'active text-white' : ''}`}
								id='nav-link'
								onClick={() => {
									set_Nav(2);
									setShowMenu(false);
									var arr = window.location.href.split('/');
									if (arr[arr.length - 1].length !== 0) {
										navigate('/');
										setTimeout(() => {
											document.getElementsByClassName('feature-scroll-point')[0].scrollIntoView();
										}, 0);
									} else {
										document.getElementsByClassName('feature-scroll-point')[0].scrollIntoView();
									}
								}}
							>
								Features
							</button>
						</li>

						<li className='nav-item'>
							<button
								className={`nav-link ${Nav === 3 ? 'active text-white' : ''}`}
								id='nav-link'
								onClick={() => {
									set_Nav(3);
									setShowMenu(false);
									var arr = window.location.href.split('/');
									if (arr[arr.length - 1].length !== 0) {
										navigate('/');
										setTimeout(() => {
											document.getElementsByClassName('team-scroll-point')[0].scrollIntoView();
										}, 0);
									} else {
										document.getElementsByClassName('team-scroll-point')[0].scrollIntoView();
									}
								}}
							>
								Team
							</button>
						</li>

						<li className='nav-item'>
							<button
								className={`nav-link ${Nav === 6 ? 'active text-white' : ''}`}
								id='nav-link'
								onClick={() => {
									set_Nav(6);
									navigate('/contact');
									setTimeout(() => {
										document.getElementsByClassName('contact-scroll-point')[0].scrollIntoView();
									}, 0);
								}}
							>
								Contact
							</button>
						</li>
						<li className='nav-item'>
							<button
								className={`nav-link ${Nav === 4 ? 'active text-white' : ''}`}
								id='nav-link'
								onClick={() => {
									// set_Nav(4);
									if (isLoggedIn) {
										set_Nav(1); // Set Nav state to 1 for "My News"
										navigate('/news'); // Navigate to the "My News" section
									} else {
										set_Nav(3); // Set Nav state to 3 for "Login"
										navigate('/login'); // Redirect to login if not logged in
									}
								}}
							>
								My News
							</button>
						</li>
						{user?.is_authority && (
							<li className='nav-item'>
								<button
									className={`nav-link ${Nav === 1 ? 'active text-white' : ''} `}
									id='nav-link'
									onClick={() => {
										set_Nav(1);
										navigate('/reports');
									}}
								>
									My Reports
								</button>
							</li>
						)}
						{isAdmin && isLoggedIn && (
							<li className='nav-item'>
								<button
									className={`nav-link ${Nav === 7 ? 'active' : ''}`}
									id='nav-link'
									onClick={() => {
										set_Nav(7);
										navigate('/admin');
									}}
								>
									Admin Panel
								</button>
							</li>
						)}
					</div>
				</nav>

				{/* overlay */}
				{showMenu && <div className='fixed inset-0 bg-black bg-opacity-50 lg:hidden' onClick={handleToggle}></div>}

				{/* Mobile Navigation */}
				<nav
					id='navv'
					className={`bg-zinc-100 shadow-2xl text-black fixed top-0 left-0 h-full w-64 transform transition-transform duration-300 ease-in-out
    								${showMenu ? 'translate-x-0' : '-translate-x-full'}
  							    `}
				>
					<div className='pt-[7rem] h-3/6 text-xl font-medium p-10 flex flex-col justify-around'>
						<div className='nav-item'>
							<button
								className={`nav-link ${Nav === 0 ? 'active' : ''}`}
								id='nav-link'
								onClick={() => {
									set_Nav(0);
									navigate('/');
									setShowMenu(false);
									setTimeout(() => {
										document.getElementsByClassName('home-scroll-point')[0].scrollIntoView();
									}, 0);
								}}
							>
								Home
							</button>
						</div>
						<div className='nav-item'>
							<button
								className={`nav-link ${Nav === 2 ? 'active ' : ''}`}
								id='nav-link'
								onClick={() => {
									set_Nav(2);
									setShowMenu(false);
									var arr = window.location.href.split('/');
									if (arr[arr.length - 1].length !== 0) {
										navigate('/');
										setTimeout(() => {
											document.getElementsByClassName('feature-scroll-point')[0].scrollIntoView();
										}, 0);
									} else {
										document.getElementsByClassName('feature-scroll-point')[0].scrollIntoView();
									}
								}}
							>
								Features
							</button>
						</div>

						<div className='nav-item'>
							<button
								className={`nav-link ${Nav === 3 ? 'active' : ''}`}
								id='nav-link'
								onClick={() => {
									set_Nav(3);
									setShowMenu(false);
									var arr = window.location.href.split('/');
									if (arr[arr.length - 1].length !== 0) {
										navigate('/');
										setTimeout(() => {
											document.getElementsByClassName('team-scroll-point')[0].scrollIntoView();
										}, 0);
									} else {
										document.getElementsByClassName('team-scroll-point')[0].scrollIntoView();
									}
								}}
							>
								Team
							</button>
						</div>

						<div className='nav-item'>
							<button
								className={`nav-link ${Nav === 6 ? 'active ' : ''}`}
								id='nav-link'
								onClick={() => {
									set_Nav(6);
									navigate('/contact');
									setTimeout(() => {
										document.getElementsByClassName('contact-scroll-point')[0].scrollIntoView();
									}, 0);
								}}
							>
								Contact
							</button>
						</div>
						<div className='nav-item'>
							<button
								className={`nav-link ${Nav === 4 ? 'active ' : ''}`}
								id='nav-link'
								onClick={() => {
									// set_Nav(4);
									if (isLoggedIn) {
										set_Nav(1); // Set Nav state to 1 for "My News"
										navigate('/news'); // Navigate to the "My News" section
									} else {
										set_Nav(3); // Set Nav state to 3 for "Login"
										navigate('/login'); // Redirect to login if not logged in
									}
								}}
							>
								My News
							</button>
						</div>
						{user?.is_authority && (
							<div className='nav-item'>
								<button
									className={`nav-link ${Nav === 1 ? 'active' : ''} `}
									id='nav-link'
									onClick={() => {
										set_Nav(1);
										navigate('/reports');
									}}
								>
									My Reports
								</button>
							</div>
						)}
						{isAdmin && isLoggedIn && (
							<div className='nav-item'>
								<button
									className={`nav-link ${Nav === 7 ? 'active' : ''}`}
									id='nav-link'
									onClick={() => {
										set_Nav(7);
										navigate('/admin');
									}}
								>
									Admin Panel
								</button>
							</div>
						)}
					</div>
				</nav>
				
				<div className='login flex justify-center items-center lg:flex lg:justify-center lg:items-center'>
					{user ? (
						<button
							className={`nav-link h-10 m-0 ${Nav === 4 ? 'active' : ''} btn-book-a-table`}
							onClick={() => handleLogout(set_user)}
						>
							Logout
						</button>
					) : (
						<button
							className={`nav-link h-10 m-0 ${Nav === 4 ? 'active' : ''} btn-book-a-table`}
							onClick={() => navigate('/login')}
						>
							Login
						</button>
					)}
				</div>
			</header>
			{/* <div id='overlay' className='overlay' onClick={closeMobileNav}></div> */}
		</div>
	);
}

export default Header;
