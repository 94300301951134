import React, { useState, useEffect } from 'react';
import Geocode from 'react-geocode';
import '../SinglePostHelper/SinglePostHelper.css';
import Post from '../../Media/Icons/Post.svg';
import Upvote from '../../Media/Icons/UpvoteArrow.svg';
import Downvote from '../../Media/Icons/DownvoteArrow.svg';
import Share from '../../Media/Icons/ShareLight.svg';
import like from '../../Media/Icons/like.svg';
import Info from '../../Media/Icons/Infonews.svg';
import moment from 'moment';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BigPlayButton, ControlBar, PlaybackRateMenuButton, Player } from 'video-react';
import loader from '../../Media/animations/loader.json';
import Lottie from 'lottie-react';
import {
	EmailIcon,
	EmailShareButton,
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	RedditIcon,
	RedditShareButton,
	TwitterIcon,
	TwitterShareButton,
	WhatsappIcon,
	WhatsappShareButton,
} from 'react-share';
import { makeNumber, timeDiff } from '../../utils/calc';
import { StringMappingType } from 'typescript';
import { useUser } from '../../UserContext';
import { useNavigate, useParams } from 'react-router-dom';
import { formatDistanceToNow, parseISO } from 'date-fns';

Geocode.setApiKey('AIzaSyBMyRKq-C8PhUUCZDXUg6y1MV4_k523T_8');
Geocode.setLanguage('en');
// Geocode.setRegion('in');
Geocode.setLocationType('ROOFTOP');

export type Comments = {
	commentId: number;
	parentId: number | null;
	isAnonymous: boolean;
	user: {
		name: string;
		id: number;
		profilepicURL: string;
	};
	likeCount: number;
	createdAt: string;
	body: string;
};

export type AllPostDataType = {
	title: string;
	content: string;
	format: string;
	image_url: any;
	name: string;
	location: string;
	time: string;
	commentCount: number;
	comments: Comments[];
	likeCount: number;
	country: string | null;
	state: string | null;
	city: string | null;
	tags: string;
	profilepicURL: string;
	isLiked: number;
};

export type User = {
	id: number;
	phoneNumber: number;
	name: string;
	isBlocked: boolean;
	gender: string;
	dob: string;
	email: string;
	country: string;
	state: string;
	district: string;
	pincode: number;
	likeCount: number;
	mediaCount: number;
	postCount: number;
	followerCount: number;
	reportCount: number;
	is_authority: boolean;
	profilepicURL: string;
};
interface CustomModalProps {
	show: boolean;
	handleClose: () => void;
	imageUrl: string;
}

const CustomModal: React.FC<CustomModalProps> = ({ show, handleClose, imageUrl }) => {
	if (!show) return null;

	return (
		<div className='custom-modal-overlay'>
			<div className='custom-modal-content'>
				<button className='custom-modal-close' onClick={handleClose}>
					×
				</button>
				<img src={imageUrl} alt='Full View' className='custom-modal-image' />
			</div>
		</div>
	);
};

function SinglePostHelper() {
	const { user, set_user } = useUser();
	const { postId } = useParams<{ postId?: string }>();

	const [AllPostData, set_AllPostData] = useState<AllPostDataType>();
	const [Oldest, set_Oldest] = useState<boolean>(false);
	const [CommentParentId, set_CommentParentId] = useState<number | null>(null);
	const [CommentName, set_CommentName] = useState<string>('');
	const [CommentBody, set_CommentBody] = useState<string>('');
	const [isLiked, setIsLiked] = useState(Boolean);
	const [showModal, setShowModal] = useState(false);

	const handleOpenModal = () => setShowModal(true);
	const handleCloseModal = () => setShowModal(false);
	const navigate = useNavigate();

	const returnToFeed = () => {
		navigate('/news');
	};

	const formatDateToRelativeTime = (dateString: string) => {
		const date = parseISO(dateString);
		return formatDistanceToNow(date, { addSuffix: true });
	};

	useEffect(() => {
		// checking authorization
		if (!postId) {
			console.error('PostId is undefined or not found in route parameters.');
			return;
		}

		const fetchData = async () => {
			const urlencoded = new URLSearchParams();
			const requestOptions = {
				method: 'GET',
				redirect: 'follow' as RequestRedirect,
				credentials: 'include' as RequestCredentials,
			};
			// fetching data
			try {
				const response = await fetch(`https://backend.b-reporter.com/api/getPost/${postId}`, requestOptions);
				const result = await response.json();

				const fileResponse = await fetch(
					`https://backend.b-reporter.com/api/media/${result.media[0].internalURL}`,
					requestOptions
				);
				const res = await fileResponse.blob();
				const file = (window.URL || window.webkitURL).createObjectURL(res);

				const postData = result.isAnonymous
					? {
							title: result.title as string,
							content: result.body as string,
							format: result.media[0].format.substring(0, 5),
							image_url: file,
							name: 'Anonymous',
							location: `${result.media[0].latitude},${result.media[0].longitude}`,
							time: `${result.createdAt.substring(0, 10)} ${result.createdAt.substring(11, 19)}`,
							commentCount: result.commentCount as number,
							comments: result.comments as Comments[],
							likeCount: result.likeCount as number,
							country: null,
							state: null,
							city: null,
							tags: result.tags as string,
							profilepicURL: result.user.profilepicURL,
							isLiked: result.isLiked,
					  }
					: {
							title: result.title as string,
							content: result.body as string,
							format: result.media[0].format.substring(0, 5),
							image_url: file,
							name: result.user.name as string,
							location: `(${result.media[0].latitude}, ${result.media[0].longitude})`,
							time: `${result.createdAt.substring(0, 10)} ${result.createdAt.substring(11, 19)}`,
							commentCount: result.commentCount as number,
							comments: result.comments as Comments[],
							likeCount: result.likeCount as number,
							country: null,
							state: null,
							city: null,
							tags: result.tags as string,
							profilepicURL: result.user.profilepicURL,
							isLiked: result.isLiked,
					  };

				set_AllPostData(postData);

				setIsLiked(postData?.isLiked === 1);

				Geocode.fromLatLng(result.media[0].latitude, result.media[0].longitude).then(
					(response) => {
						const address = response.results[0].formatted_address;

						let city = '',
							state = '',
							country = '';
						for (let i = 0; i < response.results[0].address_components.length; i++) {
							for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
								switch (response.results[0].address_components[i].types[j]) {
									case 'locality':
										city = response.results[0].address_components[i].long_name;
										break;
									case 'administrative_area_level_1':
										state = response.results[0].address_components[i].long_name;
										break;
									case 'country':
										country = response.results[0].address_components[i].long_name;
										break;
								}
							}
						}
						set_AllPostData((prevData) => ({
							...prevData!,
							city: city || null,
							state: state,
							country: country,
						}));
					},
					(error) => {
						console.error(error);
					}
				);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, [postId]);

	const createComment = async (body: string) => {
		const requestBody = {
			postId: postId!,
			parentId: CommentParentId !== null ? CommentParentId : null,
			reportId: null, // Set reportId to null if not used
			body: CommentBody,
			isAnonymous: false, // Uncomment and set if needed
		};

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(requestBody),
			redirect: 'follow' as RequestRedirect,
			credentials: 'include' as RequestCredentials,
		};
		try {
			const response = await fetch('https://backend.b-reporter.com/api/createComment', requestOptions);
			const result = await response.text();
			const cmt: Comments = {
				commentId: parseInt(result, 10),
				parentId: CommentParentId,
				isAnonymous: false,
				user: {
					name: user?.name!,
					id: user?.id!,
					profilepicURL: user?.profilepicURL!,
				},
				likeCount: 0,
				createdAt: `${new Date().toISOString()}`,
				body: body,
			};
			const temp = AllPostData!!.comments;
			temp?.push(cmt);
			set_AllPostData({ ...AllPostData!!, comments: temp, commentCount: AllPostData!!.commentCount + 1 });
			//set comment body to ""
			set_CommentBody('');
			if (CommentParentId !== null) set_CommentParentId(null);
			if (CommentName !== '') set_CommentName('');
		} catch (error) {
			console.error('Error occurred while creating comment.', error);
		}
	};

	const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			if (CommentBody == '') console.log('comment cannot be empty');
			else createComment(CommentBody);
		}
	};

	const reactPost = async (type: string) => {
		const urlencoded = new URLSearchParams();
		urlencoded.append('postId', postId!);
		urlencoded.append('type', type);
		urlencoded.append('isAnonymous', user?.name === 'Anonymous' ? 'true' : 'false');
		const requestOptions = {
			method: 'POST',
			body: urlencoded,
			redirect: 'follow' as RequestRedirect,
			credentials: 'include' as RequestCredentials,
		};

		try {
			const response = await fetch('https://backend.b-reporter.com/api/react', requestOptions);
			if (response.status === 200 || response.status === 201) {
				set_AllPostData((prevData) => ({
					...prevData!,
					likeCount: type === 'like' ? prevData!.likeCount + 1 : prevData!.likeCount - 1,
				}));
				setIsLiked(type === 'like');
			}
		} catch (error) {
			console.error('Error occurred while reacting.', error);
		}
	};

	const handleLikeClick = () => {
		reactPost(isLiked ? 'dislike' : 'like');
	};

	const CommentTree = (oldest: boolean = true, comments: Comments[]): JSX.Element => {
		const mp = new Map<number, Comments[]>();
		comments.forEach((val) => {
			if (val.parentId === null) mp.set(val.commentId, [val]);
			else mp.get(val.parentId)?.push(val);
		});

		const keys: number[] = [];
		const sortedMap = new Map<number, Comments[]>();
		mp.forEach((value, key) => keys.push(key));

		if (oldest) {
			keys.sort((a, b) => a - b).forEach((val) => sortedMap.set(val, mp.get(val)!));
		} else {
			keys.sort((a, b) => b - a).forEach((val) => sortedMap.set(val, mp.get(val)!));
		}

		const temp: JSX.Element[] = [];
		sortedMap.forEach((value) => {
			value.forEach((comment) => {
				temp.push(
					<div
						className={`row  my-1 ${comment.parentId != null ? 'ps-5 ps-lg-2 ps-xl-5' : ''}`}
						key={comment.commentId}
					>
						<div className='col-2 h-100 d-flex position-relative ' style={{ padding: '0', width: 'auto' }}>
							{comment.user?.profilepicURL === null ? (
								<div
									className='border border-info rounded-circle text-uppercase align-self-top fs-4 fw-bold text-light'
									style={{ minWidth: '40px', maxHeight: '40px', backgroundColor: '#022c56' }}
								>
									{' '}
									{comment.user?.name.charAt(0)}
								</div>
							) : (
								<img
									className='profile-image '
									style={{ minWidth: '40px', maxHeight: '40px' }}
									src={`https://backend.b-reporter.com/profilepic/${comment.user?.profilepicURL}`}
									alt='profile-pic'
								/>
							)}
						</div>
						<div className='col-10 mx-2'>
							<div className='fw-semibold text-start fs-6 row d-flex justify-content-between'>
								<span className='col-9'>{comment.user.name}</span>
								<i className='fa fa-ellipsis-h col-3 d-flex flex-row-reverse'></i>
							</div>
							<div className='fw-light text-start fs-6 my-1'>{comment.body}</div>
							<div className='row like-reply-container'>
								<div className='col-3 d-flex'>
									<img alt='' src={like} style={{ height: '25px', width: '25px' }} />
									<p>{comment.likeCount}</p>
								</div>
								<div className='col-3 d-flex'>
									{/* <p>|&nbsp;</p> */}
									<p
										onClick={() => {
											if (comment.parentId == null) set_CommentParentId(comment.commentId);
											else set_CommentParentId(comment.parentId);
											set_CommentName(comment.user.name);
										}}
									>
										Reply
									</p>
									{/* <p>&nbsp;|</p> */}
								</div>
								<div className='col-4 text-start'>
									<p>{timeDiff(comment.createdAt)}</p>
								</div>
							</div>
						</div>
					</div>
				);
			});
		});

		return <>{temp.map((val) => val)}</>;
	};

	return (
		<div>
			{!postId ? (
				<div className='d-flex justify-content-center align-items-center' style={{ height: '70vh', width: '100vw' }}>
					<button className='btn btn-light btn-lg fs-1' type='button' disabled>
						<span className='spinner-border spinner-border-lg' role='status' aria-hidden='true'></span>
						Loading...
					</button>
				</div>
			) : (
				<>
					{AllPostData ? (
						<div>
							{/* <Modal show={showModal} onHide={handleCloseModal} centered dialogClassName="full-screen-modal">
                <Modal.Header closeButton style={{ borderBottom: 'none' }}></Modal.Header>
                <Modal.Body>
                    <img src={AllPostData.image_url} alt="" className="full-screen-image" />
                </Modal.Body>
            </Modal> */}
							<CustomModal show={showModal} handleClose={handleCloseModal} imageUrl={AllPostData.image_url} />
							<div className='bg-slate-100 p-1 border-2 border-white shadow-md'>
								<div className='flex flex-row bg-black items-start'>
									<div>
										<button onClick={returnToFeed}>
											<i className='fa fa-arrow-left bg-white p-3'></i>
										</button>
									</div>
									<div className='flex bg-black text-white text-2xl font-semibold p-2 '>Post</div>
								</div>
								<div className='w-full'>
									<div className='w-full flex p-2'>
										<div className=''>
											<div
												className='w-16 h-16 flex items-center justify-center rounded-full border-2 border-gray-900 text-4xl text-white font-mono'
												style={{ backgroundColor: '#022c56' }}
											>
												{AllPostData.profilepicURL ? (
													<img
														className='w-16 h-16 flex items-center justify-center rounded-full border-2 border-gray-900'
														src={`https://backend.b-reporter.com/profilepic/${AllPostData.profilepicURL}`}
														alt='profile-pic'
														style={{ objectFit: 'cover' }}
													/>
												) : (
													AllPostData.name.charAt(0)
												)}
											</div>
										</div>
										{/* <div className='col-10 col-lg-11 text-start ps-2 ps-lg-4 mb-3'> */}
										<div className='flex flex-col items-start w-full p-2'>
											<div className=''>
												<span className='p-2'>
													<span className='text-black text-3xl font-medium'>{AllPostData.name}</span>
												</span>
											</div>
											{/* <i className='fa fa-ellipsis-h col-2 col-md-1'></i> */}
											{/* <button
													onClick={returnToFeed}
													className='bg-black text-white p-2 rounded-md w-20 right-0 text-center'
												>
												Back
												</button> */}
											<div>
												<span className=''>
													{AllPostData.city || 'Delhi'}{' '}
													<a href={'https://maps.google.com/?q=' + AllPostData.location} target='_blank'>
														<i className='fa fa-location-arrow text-white'></i>
													</a>
													&nbsp;|&nbsp;{moment.utc(AllPostData.time).format('MMM Do, YYYY')}
												</span>
											</div>
										</div>
									</div>

									<div className='p-2 grid-cols-1'>
										<div id='title' className='text-left text-3xl font-semibold'>
											{AllPostData.title}
										</div>
										<div id='content' className='text-left text-xl font-sans leading-5 p-1'>
											{AllPostData.content}
										</div>
										<div className='text-left text-xl text-blue-600'>
											{AllPostData.tags.split(',').map((val) => (
												<>#{val} </>
											))}
										</div>
									</div>
									<div className='p-2'>
										<div className=''>
											<div className=''>
												{AllPostData.format === 'image' ? (
													<img
														className='w-full rounded-2xl min-h-96 sm:min-h-72 md:min-h-80 lg:min-h-96  object-cover flex items-center justify-center border-2 border-gray-900'
														alt=''
														src={AllPostData.image_url}
														style={{ objectFit: 'cover' }}
														onClick={handleOpenModal}
													/>
												) : (
													<div className='h-100 w-100 d-flex justify-content-center'>
														<Player playsInline fluid={false} height={400} width={600}>
															<source src={AllPostData.image_url} type='video/mp4' />

															<BigPlayButton position='center' />

															<ControlBar>
																<PlaybackRateMenuButton rates={[2, 1.5, 1, 0.75, 0.5]} />
															</ControlBar>
														</Player>
													</div>
												)}
												<div
													className='water-mark relative'
													style={{
														position: 'absolute',
														bottom: 0,
														left: 0,
													}}
												>
													{/* <p style={{ margin: 0 }}>B-Reporter</p>
													<p style={{ margin: 0 }}>Created {formatDateToRelativeTime(AllPostData.time)}</p> */}
												</div>
											</div>
										</div>
									</div>
									{/* // location and date-time */}
									<div className='p-2 flex flex-row justify-between'>
										<div className='flex flex-row text-black'>
											<a
												href={'https://maps.google.com/?q=' + AllPostData.location}
												className='bg-slate-100 text-black'
												target='_blank'
											>
												<img
													className='w-10'
													alt=' map-icon'
													src='https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/Google_Maps_icon_%282015-2020%29.svg/2048px-Google_Maps_icon_%282015-2020%29.svg.png'
												/>{' '}
											</a>
										</div>
										<div className='text-xl font-bold text-slate-800'>
											{moment.utc(AllPostData.time).format('ddd MMM DD, YYYY hh:mm A')}
											{/* {AllPostData.city} */}
										</div>
									</div>
								</div>
								<div>
									<div>
										<div className='fixed bottom-2 p-2 bg-black text-white w-1/2 rounded-md'>
											<div className='' style={{ height: 'auto' }}>
												<div className='flex flex-row'>
													{/* <div className={`bg-white ${CommentName != '' ? '' : 'visually-hidden'}`}>
														<p className='badge' style={{ backgroundColor: '#022c56' }}>
															@ {CommentName}&nbsp;
															<button
																type='button'
																className='btn-close text-bg-light'
																aria-label='Close'
																style={{ height: '6px' }}
																onClick={() => {
																	set_CommentName('');
																	set_CommentParentId(null);
																}}
															></button>
														</p>
													</div> */}
													<input
														onChange={(e) => {
															set_CommentBody(e.target.value);
														}}
														value={CommentBody}
														type='text'
														className='w-full p-2 bg-black placeholder-slate-50 font-medium'
														placeholder='Post your reply'
														onKeyDown={handleKeyPress}
													/>
													<button
														className='bg-white text-black rounded-2xl font-bold border-black p-2 w-20'
														type='button'
														onClick={() => {
															if (CommentBody == '') console.log('comment cannot be empty');
															else createComment(CommentBody);
														}}
													>
														{/* <img alt='' src={Post} className='h-75' /> */}
														Reply
													</button>
												</div>
											</div>
										</div>
										<hr />
										<div className='p-1 grid grid-cols-3'>
											<div className=''>
												{/* <i className="fa fa-regular fa-comment  post-icon"></i> */}
												<i className='bi bi-chat-left  post-icon'></i>

												<p>{makeNumber(AllPostData.commentCount)}</p>
											</div>

											<div className=' '>
												<i
													className={`fa fa-thumbs-up post-icon ${isLiked ? '' : 'fa-regular'}`}
													onClick={handleLikeClick}
												></i>
												<p>{makeNumber(AllPostData.likeCount)}</p>
											</div>

											<div className=''>
												<div className='dropdown'>
													{/* <i className="fa fa-solid fa-arrow-up-from-bracket fa-lg post-icon " data-bs-toggle="dropdown" aria-expanded="false"></i> */}
													<i className='bi bi-share post-icon' data-bs-toggle='dropdown' aria-expanded='false'></i>

													<ul className='dropdown-menu'>
														<li className='m-1'>
															<EmailShareButton
																url={window.location.href}
																subject={AllPostData.title}
																body={AllPostData.content}
															>
																<span className='share-icon'>
																	<EmailIcon size={26} round />
																	&nbsp;Email
																</span>
															</EmailShareButton>
														</li>
														<li className='m-1'>
															<FacebookShareButton
																url={`https://b-reporter.com/getpost/${postId}`}
																quote={'See this Post on the B-Reporter'}
															>
																<span className='share-icon'>
																	<FacebookIcon size={26} round />
																	&nbsp;Facebook
																</span>
															</FacebookShareButton>
														</li>
														<li className='m-1'>
															<LinkedinShareButton
																url={`https://b-reporter.com/getpost/${postId}`}
																title={'See this Post on the B-Reporter'}
																summary={AllPostData.content}
																source={'https://b-reporter.com/'}
															>
																<span className='share-icon'>
																	<LinkedinIcon size={26} round />
																	&nbsp;LinkedIn
																</span>
															</LinkedinShareButton>
														</li>
														<li className='m-1'>
															<RedditShareButton
																url={`https://b-reporter.com/getpost/${postId}`}
																title={'See this Post on the B-Reporter'}
															>
																<span className='share-icon'>
																	<RedditIcon size={26} round />
																	&nbsp;Reddit
																</span>
															</RedditShareButton>
														</li>
														<li className='m-1'>
															<TwitterShareButton
																url={`https://b-reporter.com/getpost/${postId}`}
																title={'See this Post on the B-Reporter'}
															>
																<span className='share-icon'>
																	<TwitterIcon size={26} round />
																	&nbsp;Twitter
																</span>
															</TwitterShareButton>
														</li>
														<li className='m-1'>
															<WhatsappShareButton
																url={`https://b-reporter.com/getpost/${postId}`}
																title={'See this Post on the B-Reporter'}
															>
																<span className='share-icon'>
																	<WhatsappIcon size={26} round />
																	&nbsp;Whatsapp
																</span>
															</WhatsappShareButton>
														</li>
													</ul>
												</div>
											</div>
										</div>
										<hr />
										<div className='row mt-4 mb-3' style={{ height: '6%' }}>
											<div className='col-3 text-end fw-light'>
												<span>Sort by</span>
											</div>
											<div className='col-3'>
												<div className='dropdown .accordian_c'>
													<button
														className='btn btn-light dropdown-toggle border border-black accordian-button_c'
														type='button'
														data-bs-toggle='dropdown'
														aria-expanded='false'
													>
														{Oldest ? 'Old' : 'New'}
													</button>
													<ul className='dropdown-menu'>
														<li className='dropdown-item list-item-custom' onClick={() => set_Oldest(true)}>
															Old
														</li>
														<li className='dropdown-item list-item-custom' onClick={() => set_Oldest(false)}>
															New
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div id='no-scroll' className='row overflow-auto px-3' style={{ height: '60%' }}>
											<div className='d-flex flex-column mb-5'>{CommentTree(Oldest, AllPostData.comments)}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					) : (
						<div
							className='w-screen h-screen bg-slate-100'
							
						>
							<button className='' type='button' disabled>
								{/* <span className='spinner-border spinner-border-lg' role='status' aria-hidden='true'></span> */}
								<Lottie animationData={loader} />
								{/* Loading... */}
							</button>
						</div>
					)}
				</>
			)}
		</div>
	);
}

export default SinglePostHelper;
