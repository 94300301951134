import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import logo from '../../logo.svg';
import './RedirectPost.css';

const RedirectPost: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        // Add event listener for app button
        const openAppButton = document.getElementById('openApp');
        if (openAppButton) {
            openAppButton.addEventListener('click', (e) => {
                e.preventDefault();
                
                // Try to open the app
                window.location.href = `breporter://post/${id}`;
                
                // If app doesn't open within 2 seconds, redirect to Play Store
                setTimeout(() => {
                    window.location.href = 'https://play.google.com/store/apps/details?id=com.iitd.breporter3';
                }, 2000);
            });
        }
    }, [id]);

    return (
        <div className="redirect-container">
            <div className="redirect-content">
                <div className="redirect-logo">
                    <img src={logo} alt="B-Reporter Logo" />
                </div>
                <h2>Choose how to view this post:</h2>
                <a href={`breporter://post/${id}`} className="redirect-button app-button" id="openApp">
                    Open in App
                </a>
                <a href={`/?postId=${id}`} className="redirect-button web-button">
                    View on Web
                </a>
            </div>
        </div>
    );
};

export default RedirectPost; 