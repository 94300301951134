import utkarsh from '../../Media/Team/utkarsh.jpeg';
import { LinkedIn } from '@mui/icons-material';
import DeepShikha from '../../Media/Team/DeepShikha-managingDirector.png';
import lalanAdviser from '../../Media/Team/lalan-advisory.jpg';
import adityaRanjan from '../../Media/Team/adityaRanjan.jpeg';
import sarthak from '../../Media/Team/sarthak.jpeg';
import sankalp from '../../Media/Team/sankalp.jpeg';
import SaurabhAdviser from '../../Media/Team/Saurabh-advisory.png';
import ChandanAdviser from '../../Media/Team/Chandan-advisory.png';
import amitKumar from '../../Media/Team/amitKumar.jpeg';

export function TeamMembers() {
	const Members = [
		{
			name: 'Utkarsh Gupta',
			designation: 'Developer',
			image: utkarsh,
			linkedIn: 'https://www.linkedin.com/in/utkarsh-gupta-821703292/',
		},
		{
			name: 'Aditya Ranjan Sinha',
			designation: 'Developer',
			image: adityaRanjan,
			linkedIn: 'https://www.linkedin.com/in/aditya-ranjan-sinha-a3a25a332/',
		},
		{
			name: 'Sarthak Hans',
			designation: 'Developer',
			image: sarthak,
			linkedIn: 'https://www.linkedin.com/in/sarthak-hans-4a8a53223/',
		},
		{
			name: 'Sankalp Adhya',
			designation: 'Developer',
			image: sankalp,
			linkedIn: 'https://www.linkedin.com/in/sankalp-adhya-67914621b/',
		},
	];

	return (
		<div id='card-container' className='min-h-96 w-full flex justify-center items-center lg:p-5 lg:gap-5 gap-4 flex-wrap'>
			{Members.map((member, key) => (
				<div
					id='card'
					className='lg:h-[21rem] lg:w-64 h-[18rem] w-56 bg-zinc-200 flex flex-col gap-3 justify-start items-center p-3 flex-wrap rounded-2xl shadow-[0_35px_90px_-18px_rgba(0,0,0,0.3)] transition-all ease-in-out duration-500 hover:scale-110'
				>
					<div id='profile' className='lg:w-44 lg:h-44 w-32 h-32 rounded-full  overflow-hidden border-black border-1 transition-all ease-in-out duration-500 hover:scale-110'>
						<img src={member.image} alt='Profile' className='w-full h-full object-cover' />
					</div>
					<div id='description' className='w-full h-20 flex flex-col justify-center items-center gap-1 mt-3'>
						<span className='text-xl'>
							<b>{member.name}</b>
						</span>
						<span className='font-roboto'>{member.designation}</span>
						<a href={member.linkedIn} target='_blank' className='text-white' onMouseEnter={(e) => e.stopPropagation()}>
							<LinkedIn
								sx={{
									'fontSize': 30,
									'color': 'black', // Default color
									'transition': 'transform 0.3s ease, color 0.3s ease', // Smooth transition for both transform and color
									'&:hover': {
										color: '#70BFF3', // Hover color
										transform: 'scale(1.2)', // Scale up on hover
									},
								}}
							/>
						</a>
					</div>
				</div>
			))}
		</div>
	);
}

export function Founders() {
	const founders = [
		{
			name: 'Dr. Lalan Kumar',
			designation: 'Founder and NED',
			image: lalanAdviser,
			linkedIn: '',
		},
		{
			name: 'Deepshikha',
			designation: 'Managing Director',
			image: DeepShikha,
			linkedIn: '',
		},
	];

	return (
		<div id='card-container' className='min-h-96 w-full  flex justify-center items-center lg:p-5 lg:gap-5 gap-4 flex-wrap'>
			{founders.map((member, key) => (
				<div
					id='card'
					className='lg:h-[21rem] lg:w-64 h-[18rem] w-56 bg-zinc-200 flex flex-col gap-3 justify-start items-center p-3 rounded-2xl shadow-[0_35px_90px_-18px_rgba(0,0,0,0.3)] transition-all ease-in-out duration-500 hover:scale-110'
				>
					<div id='profile' className='lg:w-44 lg:h-44 w-32 h-32 rounded-full border-black border-1 overflow-hidden transition-all ease-in-out duration-500 hover:scale-110'>
						<img src={member.image} alt='Profile' className='w-full h-full object-cover' />
					</div>
					<div id='description' className='w-full h-20 flex flex-col justify-center items-center gap-1 mt-3'>
						<span className='font-bold text-2xl'>{member.name}</span>
						<span className='font-normal'>{member.designation}</span>
						<a href={member.linkedIn} target='_blank' className='text-white' onMouseEnter={(e) => e.stopPropagation()}>
							<LinkedIn
								sx={{
									'fontSize': 30,
									'color': 'black', // Default color
									'transition': 'transform 0.3s ease, color 0.3s ease', // Smooth transition for both transform and color
									'&:hover': {
										color: '#70BFF3', // Hover color
										transform: 'scale(1.2)', // Scale up on hover
									},
								}}
							/>
						</a>
					</div>
				</div>
			))}
		</div>
	);
}

export function AdvisoryTeam() {
	const advisors = [
		{
			name: 'Saurabh Singh',
			image: SaurabhAdviser,
			description:
				'Mr. Saurabh Singh is working as director of credit at Trifecta Capital. He had been the co-founder of Intellinovate Solutions, Crispy Tokri, and Clove Dental. He is an alumnus of IIT BHU and IIM Ahmedabad.',
		},
		{
			name: 'Chandan Kumar',
			image: ChandanAdviser,
			description:
				'Mr. Chandan Kumar is working with Tata Steel as senior planning manager. He is an alumnus of IIT BHU and IIM Ahmedabad.',
		},
		{
			name: 'Amit Kumar',
			image: amitKumar,
			description:
				'Amit Kumar is a PMRF Ph.D. in the area of Cognitive Neuroscience and Meditation. He holds an M. Tech from IIT Delhi, MA in Philosophy, MA in Psychology from DU, and B. Tech from NIT Calicut. He is State Head of "TheSatyarthFoundation” and author of Indian express newspaper.',
		},
	];

	return (
		<div id='card-container' className=' w-full flex justify-center items-center lg:p-5 lg:gap-5 gap-4 flex-wrap'>
			{advisors.map((adviser, key) => (
				<div
					id='card'
					className='lg:h-[27rem] lg:w-72 h-[26rem] w-96 bg-zinc-200 flex flex-col gap-3 justify-start items-center p-3 rounded-2xl shadow-[0_35px_90px_-18px_rgba(0,0,0,0.3)] transition-all ease-in-out duration-500 hover:scale-110'
				>
					<div id='profile' className='lg:w-44 lg:h-44 w-32 h-32 rounded-full border-black border-1 overflow-hidden transition-all ease-in-out duration-500 hover:scale-110'>
						<img src={adviser.image} alt='Profile' className='w-full h-full object-cover' />
					</div>
					<div id='description' className='w-full flex flex-col justify-center items-center mt-2 gap-1'>
						<span className='font-bold text-2xl'>{adviser.name}</span>
						<span className='font-light text-sm text-start'>{adviser.description}</span>
					</div>
				</div>
			))}
		</div>
	);
}
