// import React, { useState } from 'react';
// import './AuthorityAdminPanel.css';

// const AuthorityAdminPanel = () => {
//   const [responseMessage, setResponseMessage] = useState<string>('');
//   const [showAlert, setShowAlert] = useState(false);
//   const [showForm, setShowForm] = useState(false);

//   const [formData, setFormData] = useState({
//     name: '',
//     phoneNumber: '',
//     verificationDocuments: null as File | null,
//   });

//   const handleCreateAuthority = async (e: React.FormEvent<HTMLFormElement>) => {
//     e.preventDefault();
//     const data = new FormData();
//     data.append('name', formData.name);
//     data.append('phoneNumber', '91' + formData.phoneNumber);
//     if (formData.verificationDocuments) {
//       data.append('file', formData.verificationDocuments);
//     }

//     try {
//       const response = await fetch('https://backend.b-reporter.com/users/authority/createAuthority', {
//         method: 'POST',
//         body: data,
//         credentials: 'include' as RequestCredentials
//       });

//       const result = await response.json();
//       console.log(result)
//       if (response.ok) {
//         console.log("Authority created successfully");
//         setShowAlert(true);

//         setTimeout(() => {
//           setShowAlert(false);
//         }, 3000);
//         setResponseMessage(result.message);
//       } else {
//         setResponseMessage(result.message || 'Error creating authority');
//       }
//     } catch (error) {
//       setResponseMessage('Error creating authority');
//     }
//   };

//   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setFormData((prevData) => ({
//       ...prevData,
//       verificationDocuments: e.target.files ? e.target.files[0] : null,
//     }));
//   };

//   return (
//     <div className="admin-panel-bg">
//     <div className="admin-panel-container">
//       <button className="admin-panel-button" onClick={() => setShowForm(true)}>
//         Create Authority
//       </button>

//       {showForm && (
//         <form onSubmit={handleCreateAuthority}>
//             <div className="">
//             {/* <label htmlFor="formGroupExampleInput" className='text-left w-full'>Name</label> */}
//             <input
//               type="text"
//               className=""
//               id="formGroupExampleInput"
//               name="name"
//               placeholder="Enter Name"
//               value={formData.name}
//               onChange={handleInputChange}
//               required
//             />
//           </div>
//           <div className="">
//             {/* <label htmlFor="formGroupExampleInput2" className='text-left w-full'>Phone Number:</label> */}
//             <input
//               type="text"
//               className=""
//               id="formGroupExampleInput2"
//               name="phoneNumber"
//               placeholder="Enter Phone Number"
//               value={formData.phoneNumber}
//               onChange={handleInputChange}
//               required
//             />
//           </div>
//           <div className="">
//             {/* <label htmlFor="exampleFormControlFile1" className='text-left w-full'>Verification Documents:</label> */}
//             <input
//               type="file"
//               className=""
//               id="exampleFormControlFile1"
//               name="verificationDocuments"
//               onChange={handleFileChange}
//               required
//             />
//           </div>

//           <button type="submit" className="btn btn-primary">
//             SUBMIT
//           </button>
//         </form>
//       )}
//       {showAlert && (
//         <div className="alert alert-success d-flex align-items-center" role="alert">
//           <svg className="bi flex-shrink-0 me-2" role="img" aria-label="Success:">
//             <use href="#check-circle-fill" />
//           </svg>
//           <div>
//             {responseMessage || 'Authority created successfully.'}
//           </div>
//         </div>
//       )}
//     </div>
//     </div>
//   );
// };

// export default AuthorityAdminPanel;

import React, { useState, useEffect } from 'react';
import './AuthorityAdminPanel.css';

const AuthorityAdminPanel = () => {
	const [activeTab, setActiveTab] = useState<'create' | 'delete'>('create');
	const [responseMessage, setResponseMessage] = useState<string>('');
	const [showAlert, setShowAlert] = useState(false);
	// const [showForm, setShowForm] = useState(false);

	const [formData, setFormData] = useState({
		name: '',
		phoneNumber: '',
		verificationDocuments: null as File | null,
	});

	const [authorities, setAuthorities] = useState<{ id: number; name: string }[]>([]);

	// Fetch authorities when Delete tab is active
	useEffect(() => {
		if (activeTab === 'delete') {
			fetchAuthorities();
		}
	}, [activeTab]);

	const fetchAuthorities = async () => {
		try {
			const response = await fetch('https://backend.b-reporter.com/users/list', {
				method: 'GET',
				credentials: 'include',
			});

			const result = await response.json();
			if (response.ok) {
				setAuthorities(result.authorities);
			} else {
				setResponseMessage(result.message || 'Error fetching authorities');
			}
		} catch (error) {
			setResponseMessage('Error fetching authorities');
		}
	};

	const handleDeleteAuthority = async (id: number) => {
		try {
			const response = await fetch(`https://backend.b-reporter.com/users/${id}`, {
				method: 'DELETE',
				credentials: 'include',
			});

			const result = await response.json();
			if (response.ok) {
				setResponseMessage(result.message || 'Authority deleted successfully');
				fetchAuthorities(); // Refresh the list after deletion
			} else {
				setResponseMessage(result.message || 'Error deleting authority');
			}
		} catch (error) {
			setResponseMessage('Error deleting authority');
		}
	};

	const handleCreateAuthority = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const data = new FormData();
		data.append('name', formData.name);
		data.append('phoneNumber', '91' + formData.phoneNumber);
		if (formData.verificationDocuments) {
			data.append('file', formData.verificationDocuments);
		}

		try {
			const response = await fetch('https://backend.b-reporter.com/users/authority/createAuthority', {
				method: 'POST',
				body: data,
				credentials: 'include',
			});

			const result = await response.json();
			if (response.ok) {
				setShowAlert(true);
				setTimeout(() => {
					setShowAlert(false);
				}, 3000);
				setResponseMessage(result.message);
			} else {
				setResponseMessage(result.message || 'Error creating authority');
			}
		} catch (error) {
			setResponseMessage('Error creating authority');
		}
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFormData((prevData) => ({
			...prevData,
			verificationDocuments: e.target.files ? e.target.files[0] : null,
		}));
	};

	return (
		<div className='admin-panel-bg'>
			<div className='admin-panel-container'>
				<div className='navigation-buttons'>
					<button
						className={`admin-panel-button ${activeTab === 'create' ? 'active' : ''}`}
						onClick={() => setActiveTab('create')}
					>
						Create Authority
					</button>
					<button
						className={`admin-panel-button ${activeTab === 'delete' ? 'active' : ''}`}
						onClick={() => setActiveTab('delete')}
					>
						Delete Authority
					</button>
				</div>

				{activeTab === 'create' && (
					<div>
						{/* <button className="admin-panel-button" onClick={() => setShowForm(true)}>
              Create Authority
            </button> */}

						{/* {showForm && ( */}
						<form onSubmit={handleCreateAuthority}>
							<div className='form-group'>
								<input
									type='text'
									className=''
									name='name'
									placeholder='Enter Name'
									value={formData.name}
									onChange={handleInputChange}
									required
								/>
							</div>
							<div className='form-group'>
								<input
									type='text'
									className=''
									name='phoneNumber'
									placeholder='Enter Phone Number'
									value={formData.phoneNumber}
									onChange={handleInputChange}
									required
								/>
							</div>
							<div className='form-group'>
								<input type='file' className='' name='verificationDocuments' onChange={handleFileChange} required />
							</div>

							<div className='flex flex-col p-4'>
								<select
									defaultValue={'hostel'}
									name='form-type'
									id='form-type'
									className='px-5 py-3 bg-blue-50 text-lg border-2 border-gray-500 rounded-lg shadow-sm focus:outline-none focus:ring-2'
								>
									<option value='hostel'>Hostel Form</option>
									<option value='custom'>Custom Template</option>
								</select>
							</div>

							<button type='submit' className='btn-primary'>
								SUBMIT
							</button>
						</form>
					</div>
				)}

				{activeTab === 'delete' && (
					<div>
						<h3>Authorities List</h3>
						<ul className='authorities-list'>
							{authorities.map((authority) => (
								<li key={authority.id} className='authority-item'>
									<span>
										{authority.name} (ID: {authority.id})
									</span>
									<button className='reject-button' onClick={() => handleDeleteAuthority(authority.id)}>
										Delete
									</button>
								</li>
							))}
						</ul>
					</div>
				)}

				{showAlert && (
					<div className='alert alert-success' role='alert'>
						{responseMessage}
					</div>
				)}
			</div>
		</div>
	);
};

export default AuthorityAdminPanel;
