// import React from 'react';
// import '../ContactUs/ContactUs.css';
// import Footer from '../../compos/Footer/Footer';
// import Header from '../../compos/Header/Header';
// import { LoginProps } from '../Login/Login';
// import BReporter from '../../Media/Icons/BReporter.png';
// import { useUser } from '../../UserContext';
// import { Navigate, useNavigate } from 'react-router-dom';
// const ContactUs = () => {
// 	const {user,set_user} = useUser();
// 	const navigate = useNavigate();
// 	window.addEventListener('load', function () {
// 		const form = document.getElementById('my-form') as HTMLFormElement;
// 		form.addEventListener('submit', function (e) {
// 			e.preventDefault();
// 			const data = new FormData(form);
// 			const action = form.action;
// 			fetch(action, {
// 				method: 'POST',
// 				body: data,
// 			}).then(() => {
// 				alert('Success!');
// 			});
// 		});
// 	});

// 	return (
// 		<div style={{ overflowX: 'hidden' }}>
// 			<Header />
// 			<div style={{ overflowX: 'hidden', marginTop: '90px' }}>
// 				{/* <div className='row msp-lab'>
// 					<div className='h-100'>
// 						<div className='row d-flex justify-center align-items-center h-100' style={{ marginBottom: '0px' }}>
// 							<div className='col-5 col-sm-3 d-flex justify-center align-items-center' style={{ padding: '10px' }}>
// 								<img
// 									className='responsive-img p-3 '
// 									style={{ maxHeight: '250px', maxWidth: '250px' }}
// 									src='https://web.iitd.ac.in/~lalank/msp/images/iitd_logo.png'
// 								/>
// 							</div>
// 							<div
// 								className='col-7 col-sm-7 d-flex justify-center align-items-center flex-col'
// 								style={{ paddingTop: '10px', paddingRight: '40px' }}
// 							>
// 								<h2 className='w-100'>
// 									<b>Multichannel Signal Processing Laboratory</b>
// 								</h2>
// 							</div>
// 						</div>
// 					</div>
// 				</div> */}
// 				<div className='user-contact row'>
// 					<div className='contact-us'>
// 						<form
// 							id='my-form'
// 							className='forms_form'
// 							method='POST'
// 							action='https://script.google.com/macros/s/AKfycbwVOMlBZXq-XhzgC1SM180lF-MgwPEM8eKuiFawEdd1AentrQE4-eNWjuE6y4kD4KZ7Vw/exec'
// 							onSubmit={(e) => {
// 								navigate('/contact');
// 							}}
// 						>
// 							<h2 className='forms_title'>Drop us a line</h2>
// 							<fieldset className='forms_fieldset'>
// 								<div className='forms_field'>
// 									<i className='fa fa-solid fa-user-circle mx-2'></i>
// 									<input type='text' name='Name' placeholder='Name' className='forms_field-input' required />
// 								</div>
// 								<div className='forms_field'>
// 									<i className='fa fa-solid fa-envelope-open mx-2'></i>
// 									<input type='email' name='Email' placeholder='Email' className='forms_field-input' required />
// 								</div>
// 								<div className='forms_field'>
// 									<i className='fa fa-solid fa-phone mx-2'></i>
// 									<input placeholder='Phone' name='Phone' className='forms_field-input' required />
// 								</div>
// 								<div className='forms_field'>
// 									<i className='fa fa-solid fa-comment mx-2'></i>
// 									<textarea id='customerNote' name='Message' required rows={5} placeholder='Message'></textarea>
// 								</div>
// 							</fieldset>
// 							<div className='forms_buttons'>
// 								<input type='submit' value='Submit' className='forms_buttons-action' />
// 							</div>
// 						</form>
// 					</div>
// 				</div>
// 				<div className='row'>
// 					<iframe
// 					 src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2063.328484620443!2d77.24045267337166!3d28.498921220969233!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce10dd26b4c81%3A0xce258c6c7ac1ffae!2sGali%20No.%203%2C%20Sangam%20Vihar%2C%20New%20Delhi%2C%20Delhi!5e0!3m2!1sen!2sin!4v1720294584797!5m2!1sen!2sin" loading="lazy" 
// 						style={{ minHeight: '400px', minWidth: '100%' }}
// 						allowFullScreen
// 					></iframe>
// 					</div>
// 			</div>
// 			<Footer />
// 		</div>
// 	);
// };

// export default ContactUs;


import React from 'react';
import '../ContactUs/ContactUs.css';
import Footer from '../../compos/Footer/Footer';
import Header from '../../compos/Header/Header';
import { useUser } from '../../UserContext';
import { useNavigate } from 'react-router-dom';

const ContactUs = () => {
  const { user, set_user } = useUser();
  const navigate = useNavigate();

  const handleSubmit = async (event: { preventDefault: () => void; target: any; }) => {
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);
    const data = Object.fromEntries(formData.entries());
	console.log(data);

    try {
      const response = await fetch(
        'https://backend.b-reporter.com/form/submit-form',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        }
      );
	  console.log(response);

      const result = await response.json();
	  console.log(result)

      if (response.ok) {
        alert('Your message has been sent successfully!'); // Redirect or reload as needed
      } else {
        alert('Failed to send the message. Please try again later.');
      }
    } catch (error) {
      console.error('Error submitting the form:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  return (
    <div style={{ overflowX: 'hidden' }} className='contact-scroll-point'>
      <Header />
      <div style={{ overflowX: 'hidden', marginTop: '90px' }}>
        <div className='user-contact row'>
          <div className='contact-us'>
            <form
              id='my-form'
              className='forms_form'
              onSubmit={handleSubmit}
            >
              <h2 className='forms_title'>Drop us a line</h2>
              <fieldset className='forms_fieldset'>
                <div className='forms_field'>
                  <i className='fa fa-solid fa-user-circle mx-2'></i>
                  <input
                    type='text'
                    name='Name'
                    placeholder='Name'
                    className='forms_field-input'
                    required
                  />
                </div>
                <div className='forms_field'>
                  <i className='fa fa-solid fa-envelope-open mx-2'></i>
                  <input
                    type='email'
                    name='Email'
                    placeholder='Email'
                    className='forms_field-input'
                    required
                  />
                </div>
                <div className='forms_field'>
                  <i className='fa fa-solid fa-phone mx-2'></i>
                  <input
                    type='text'
                    name='Phone'
                    placeholder='Phone'
                    className='forms_field-input'
                    required
                  />
                </div>
                <div className='forms_field'>
                  <i className='fa fa-solid fa-comment mx-2'></i>
                  <textarea
                    id='customerNote'
                    name='Message'
                    required
                    rows={5}
                    placeholder='Message'
                  ></textarea>
                </div>
              </fieldset>
              <div className='forms_buttons'>
                <input
                  type='submit'
                  value='Submit'
                  className='forms_buttons-action'
                />
              </div>
            </form>
          </div>
        </div>
        <div className='row'>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d7011.531817397115!2d77.20092965626203!3d28.51668992073938!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sKh.%20No.%20293%20S%2FF%2C%20Western%20Marg%2C%20Saidulajab%2C%20Near%20Kher%20Singh%20Estate%2C%20Saket%2C%20New%20Delhi%20-%20110030!5e0!3m2!1sen!2sin!4v1736932539874!5m2!1sen!2sin"
            loading='lazy'
            style={{ minHeight: '400px', minWidth: '100%' }}
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;

// 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2063.328484620443!2d77.24045267337166!3d28.498921220969233!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce10dd26b4c81%3A0xce258c6c7ac1ffae!2sGali%20No.%203%2C%20Sangam%20Vihar%2C%20New%20Delhi%2C%20Delhi!5e0!3m2!1sen!2sin!4v1720294584797!5m2!1sen!2sin' // PREVIOUS LOCATION