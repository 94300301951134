import React from "react";
import './Post.css';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { EmailShareButton, EmailIcon, FacebookShareButton, FacebookIcon, LinkedinShareButton, LinkedinIcon, RedditShareButton, RedditIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon } from 'react-share';
import Placeholder from '../../Media/Team/placeholder.png'
import { useNavigate } from "react-router-dom";



interface Media {
    internalURL: string;
    format: string;
    latitude: string;
    longitude: string;
    createdAt: string;
  }
  
  interface Post {
    id: number;
    media: Media[];
    title: string;
    tags: string;
    body:string
    user:{name:string,
      id:number,
      profilepicURL:string;
    }
    isAnonymous: boolean;
    status: string;
    likeCount: number;
    commentCount: number;
    isLiked: number;
    createdAt: string;
    updatedAt: string;
  }
  
  interface PostProps {
    post: Post;
    onDelete: (postId: number) => void;
  }
  




const Post: React.FC<PostProps> = ({post, onDelete}) => {
  
    const navigate = useNavigate();
  const formatDateToRelativeTime = (dateString:string) => {
    const date = parseISO(dateString);
    return formatDistanceToNow(date, { addSuffix: true });
  };
    
  const getUserInitial = (name: string) => {
    return name.charAt(0).toUpperCase();
  };
       
  const returnToFeed = () =>{
    navigate('/news');
  }
  return (<>      
   

    <div className="post" key={post.id}>
    <div className="left-column">
                                        {/* <img className="profile-image" src={Placeholder} /> */}
                                        <img className="profile-image" src={`https://backend.b-reporter.com/profilepic/${post.user.profilepicURL}`} alt='profile-pic'/>
                                    </div>
                                    <div className="right-column">
                                        <article className="top-row">
                                            <div className="post-header">
                                                <div className="name-time">
                                                    <strong>
                                                        <span>{post.user.name}</span>
                                                    </strong>
                                                    
                                                    <span className="time">{formatDateToRelativeTime(post.createdAt)}</span>
                                                </div>
                                                <div className="details">
                                                    <i className="fa fa-light fa-ellipsis fa-lg"></i>
                                                </div>
                                            </div>
                                            <button onClick={returnToFeed} className="bg-black text-white">Back</button>
                                            {/* <p>{post.title}</p> */}
                                            <img src={`https://backend.b-reporter.com/media/${post.media[0].internalURL}`} alt="IIT-DELHI" className='post-image' />
                                        </article>
                                        <div className="bottom-row">
                                            
                                           
                                            <div onClick={() => { window.location.href = `/post/${post.id}` }}>
                                                <div>
                                                    <i className="fa fa-regular fa-comment fa-lg"></i>
                                                </div>
                                                <span>{post.commentCount}</span>
                                            </div>
                                            <div>
                                                <div>
                                                    <i className="fa fa-regular fa-thumbs-up fa-lg"></i>
                                                </div>
                                                <span>{post.likeCount}</span>
                                            </div>
                                            
                                            <div>
                                                <div className='btn-group dropstart'>
                                                    <i className="fa fa-solid fa-arrow-up-from-bracket fa-lg" data-bs-toggle="dropdown" aria-expanded="false"></i>
                                                    <ul className="dropdown-menu">
                                                        <li className='p-2'>
                                                            <EmailShareButton url={window.location.href} subject={post.title} body={post.body}>
                                                                <span className="share-icon">
                                                                    <EmailIcon size={26} round />
                                                                    &nbsp;Email
                                                                </span>
                                                            </EmailShareButton>
                                                        </li>
                                                        <li className='p-2'>
                                                            <FacebookShareButton url={window.location.href} quote={post.title}>
                                                                <span className="share-icon">
                                                                    <FacebookIcon size={26} round />
                                                                    &nbsp;Facebook
                                                                </span>
                                                            </FacebookShareButton>
                                                        </li>
                                                        <li className='p-2'>
                                                            <LinkedinShareButton url={window.location.href} title={post.title} summary={post.body} source={'https://b-reporter.com/'}>
                                                                <span className="share-icon">
                                                                    <LinkedinIcon size={26} round />
                                                                    &nbsp;LinkedIn
                                                                </span>
                                                            </LinkedinShareButton>
                                                        </li>
                                                        <li className='p-2'>
                                                            <RedditShareButton url={window.location.href} title={post.title}>
                                                                <span className="share-icon">
                                                                    <RedditIcon size={26} round />
                                                                    &nbsp;Reddit
                                                                </span>
                                                            </RedditShareButton>
                                                        </li>
                                                        <li className='p-2'>
                                                            <TwitterShareButton url={window.location.href} title={post.title}>
                                                                <span className="share-icon">
                                                                    <TwitterIcon size={26} round />
                                                                    &nbsp;Twitter
                                                                </span>
                                                            </TwitterShareButton>
                                                        </li>
                                                        <li className='p-2'>
                                                            <WhatsappShareButton url={window.location.href} title={post.title}>
                                                                <span className="share-icon">
                                                                    <WhatsappIcon size={26} round />
                                                                    &nbsp;Whatsapp
                                                                </span>
                                                            </WhatsappShareButton>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='more-btn'>
                                            {/* <span onClick={() => { window.location.href = `/post/${post.id}` }}>Show More...</span> */}
                                            <p>{post.body}</p>
                                        </div>
                                      <button
                                        onClick={() => onDelete(post.id)}
                                        className="post-delete-btn"
                                      >
                                        Delete
                                      </button>
                                    </div>
                                    
                                </div>
</>
        );
}

export default Post;