import React, { createContext, useContext, useState, useEffect } from 'react';
import { AllPostDataType } from './compos/AllPosts/MyFeed';
import { AllReportDataType } from './compos/AllReports/MyReports';

interface DataContextType {
    posts: Record<number, AllPostDataType[]>;
    setPosts: (topic: number, posts: AllPostDataType[]) => void;
    reports: AllReportDataType[];
    setReports: (reports: AllReportDataType[]) => void;
    clearData: () => void;
    profilePicCache: Record<string, string>;
    lastRefreshTime: number;
}

const DataContext = createContext<DataContextType | undefined>(undefined);

export function DataProvider({ children }: { children: React.ReactNode }) {
    const [posts, setPosts] = useState<Record<number, AllPostDataType[]>>({});
    const [reports, setReports] = useState<AllReportDataType[]>([]);
    const [profilePicCache, setProfilePicCache] = useState<Record<string, string>>({});
    const [lastRefreshTime, setLastRefreshTime] = useState<number>(Date.now());

    // Load cached data on mount
    useEffect(() => {
        // Check if this is a real page refresh by comparing navigation type
        const isRealPageRefresh = (
            window.performance &&
            window.performance.navigation &&
            window.performance.navigation.type === 1
        ) || !sessionStorage.getItem('lastRefreshTime');

        if (isRealPageRefresh) {
            // Clear existing data on real refresh
            setPosts({});
            setReports([]);
            const currentTime = Date.now();
            setLastRefreshTime(currentTime);
            sessionStorage.setItem('lastRefreshTime', currentTime.toString());
        } else {
            const storedRefreshTime = sessionStorage.getItem('lastRefreshTime');
            if (storedRefreshTime) {
                setLastRefreshTime(parseInt(storedRefreshTime));
            }
        }

        // Load cached data
        const cachedData = localStorage.getItem('appData');
        if (cachedData && !isRealPageRefresh) {
            const { posts: cachedPosts, reports: cachedReports, profilePics } = JSON.parse(cachedData);
            setPosts(cachedPosts || {});
            setReports(cachedReports || []);
            setProfilePicCache(profilePics || {});
        }
    }, []);

    // Save data to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('appData', JSON.stringify({
            posts,
            reports,
            profilePics: profilePicCache
        }));
    }, [posts, reports, profilePicCache]);

    const setPostsForTopic = (topic: number, newPosts: AllPostDataType[]) => {
        setPosts(prev => ({ ...prev, [topic]: newPosts }));
        
        // Cache profile pictures
        newPosts.forEach(post => {
            if (post.profilepicURL && !profilePicCache[post.profilepicURL]) {
                setProfilePicCache(prev => ({
                    ...prev,
                    [post.profilepicURL]: `https://backend.b-reporter.com/profilepic/${post.profilepicURL}`
                }));
            }
        });
    };

    const setReportsWithCache = (newReports: AllReportDataType[]) => {
        setReports(newReports);
        
        // Cache profile pictures
        newReports.forEach(report => {
            if (report.profilepicURL && !profilePicCache[report.profilepicURL]) {
                setProfilePicCache(prev => ({
                    ...prev,
                    [report.profilepicURL]: `https://backend.b-reporter.com/profilepic/${report.profilepicURL}`
                }));
            }
        });
    };

    const clearData = () => {
        setPosts({});
        setReports([]);
        localStorage.removeItem('appData');
    };

    return (
        <DataContext.Provider value={{
            posts,
            setPosts: setPostsForTopic,
            reports,
            setReports: setReportsWithCache,
            clearData,
            profilePicCache,
            lastRefreshTime
        }}>
            {children}
        </DataContext.Provider>
    );
}

export function useData() {
    const context = useContext(DataContext);
    if (context === undefined) {
        throw new Error('useData must be used within a DataProvider');
    }
    return context;
} 