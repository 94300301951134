import React, { useState, useEffect } from 'react';
import Header from '../../compos/Header/Header';
import BasicSlider from '../../compos/BasicSlider/BasicSlider';
import Footer from '../../compos/Footer/Footer';
import './Home.css';
import { LoginProps } from '../Login/Login';
import utkarsh from '../../Media/Team/utkarsh.jpeg';
import lalan from '../../Media/Team/lalan_kumar.png';
import lalanAdviser from '../../Media/Team/lalan-advisory.jpg';
import DeepShikha from '../../Media/Team/DeepShikha-managingDirector.png';
import CCTVImg from '../../Media/Images/Slide_CCTV.jpeg';
import PostFactoImg from '../../Media/Images/post-facto-feature-img.jpeg';
import Sensitive from '../../Media/Images/sensitive.jpg';
import People from '../../Media/Images/people.jpg';
import LadyWithWatch from '../../Media/Images/lady-with-watch-croam-img.jpg';
import GroundReport from '../../Media/Images/ground-report-img.jpg';
import RecordReport from '../../Media/Images/record-and-report-img.jpg';
import LocationAware from '../../Media/Images/locationAware.png';
import Riskometer from '../../Media/Images/riskometer-img.jpg';
import shresth from '../../Media/Team/shresth_ojha.png';
import gopal from '../../Media/Team/gopal.png';
import mayank from '../../Media/Team/mayank.jpeg';
import suraaj from '../../Media/Team/suraaj.jpeg';
import placeholder from '../../Media/Team/placeholder.png';
import simranY from '../../Media/Team/simran_yadav.png';
import ashrut from '../../Media/Team/ashrut_sinha.png';
import AboutUsImage from '../../Media/Images/Breporterpng.jpg';
import { useUser } from '../../UserContext';
import FeatureSlider from '../../compos/FeatureSlider/FeatureSlider';
import { AdvisoryTeam, Founders, TeamMembers } from '../../compos/Teams/TeamMembers';

const Home = () => {
	const { user, setIsAdmin, setIsLoggedIn, set_user } = useUser();
	const [animationClass, setAnimationClass] = useState('');
  const [post, setPost] = useState<any>(null);
  const [isPost, setIsPost] = useState(false);

	const checkIfAdmin = async (setIsAdmin: React.Dispatch<React.SetStateAction<boolean>>) => {
		try {
			const response = await fetch('https://backend.b-reporter.com/api/isAdmin', {
				method: 'GET',
				credentials: 'include',
			});

			if (!response.ok) {
				throw new Error('Network response was not ok');
			}

			const data = await response.json();

			// Check if the API response indicates the user is an admin
			setIsAdmin(data);
		} catch (error) {
			console.error('There was a problem with the fetch operation:', error);
			setIsAdmin(false);
		}
	};

	useEffect(() => {
		const token = localStorage.getItem('authToken');
		if (token) {
			const profileRequestOptions: RequestInit = {
				method: 'GET',
				redirect: 'follow',
				credentials: 'include',
				headers: {
					Authorization: `Bearer ${token}`,
				},
			};

			fetch('https://backend.b-reporter.com/user/myProfile', profileRequestOptions)
				.then((response) => {
					if (response.ok) {
						return response.json();
					} else {
						throw new Error('Profile request failed');
					}
				})
				.then((result) => {
					set_user(result);
					setIsLoggedIn(true);
					checkIfAdmin(setIsAdmin);
				})
				.catch((error) => {
					console.log('Error occurred while fetching profile:', error);
					setIsLoggedIn(false);
					set_user(null);
				});
		} else {
			setIsLoggedIn(false);
			set_user(null);
		}
	}, [set_user, setIsLoggedIn, setIsAdmin]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const postId = urlParams.get('postId');
    console.log(postId);
    console.log("hello world");
    if (postId) {
      setIsPost(true);
      fetchPost(postId);
    }
  }, []);

  const fetchPost = async (postId: string) => {
    try {
      const requestOptions: RequestInit = {
        method: 'GET',
        credentials: 'include',
      };
  
      // Fetching post data
      const response = await fetch(`https://backend.b-reporter.com/api/getPost/${postId}`, requestOptions);
      if (!response.ok) throw new Error('Failed to fetch post');
      const result = await response.json();
  
      // Fetching media file
      const fileResponse = await fetch(`https://backend.b-reporter.com/api/media/${result.media[0].internalURL}`, requestOptions);
      if (!fileResponse.ok) throw new Error('Failed to fetch media file');
      const blob = await fileResponse.blob();
      const fileUrl = (window.URL || window.webkitURL).createObjectURL(blob);
  
      // Formatting post data
      const postData = result.isAnonymous
        ? {
            title: result.title,
            content: result.body,
            format: result.media[0].format.substring(0, 5),
            image_url: fileUrl,
            name: 'Anonymous',
            location: `${result.media[0].latitude}, ${result.media[0].longitude}`,
            time: `${result.createdAt.substring(0, 10)} ${result.createdAt.substring(11, 19)}`,
            commentCount: result.commentCount,
            comments: result.comments,
            likeCount: result.likeCount,
            tags: result.tags,
            profilepicURL: result.user?.profilepicURL,
            isLiked: result.isLiked,
          }
        : {
            title: result.title,
            content: result.body,
            format: result.media[0].format.substring(0, 5),
            image_url: fileUrl,
            name: result.user?.name,
            location: `${result.media[0].latitude}, ${result.media[0].longitude}`,
            time: `${result.createdAt.substring(0, 10)} ${result.createdAt.substring(11, 19)}`,
            commentCount: result.commentCount,
            comments: result.comments,
            likeCount: result.likeCount,
            tags: result.tags,
            profilepicURL: result.user?.profilepicURL,
            isLiked: result.isLiked,
          };
  
      setPost(postData);
    } catch (error) {
      console.error('Error fetching post:', error);
    }
  };

	return (
    isPost && post ? (
      <div className="specific-post">
        <h2>{post.title}</h2>
        <p>{post.content}</p>
        <img src={post.image_url} alt="Post Media" />
        <p>Posted by: {post.name}</p>
        <p>Location: {post.location}</p>
        <p>Tags: {post.tags}</p>
        <p>Likes: {post.likeCount}</p>
        <p>Comments: {post.commentCount}</p>
        <div className="login-prompt">
          <p>For more posts, please login to the website.</p>
          <button
            onClick={() => {
              window.location.href = '/login'; // Adjust the URL as per your routing setup
            }}
            className="login-button"
          >
            Go to Login
          </button>
        </div>
      </div>
    ) :
		
    <div className='section-bg bg-zinc-100 home-scroll-point'>
			<Header />
			<BasicSlider />

			{/* -------------*******-------------- */}

			{/*feature section*/}
			<div className='feature-scroll-point section-bg flex items-center justify-center flex-col gap-10'>
				<FeatureSlider />
			</div>

			{/* Basic Heading changed */}
			{/* <div className='team-scroll-point'></div>
			<div className='section-header pt-5'> */}

			<div className='team-scroll-point p-5 flex justify-center items-center flex-col gap-3 bg-zinc-100'>
				<div className=''>
					<p className='text-5xl mt-12'><b>Founders</b></p>
					<Founders />
				</div>

				<div className='members-heading'>
					<p className='font-roboto text-5xl'><b>Team Members</b></p>
				</div>
					<TeamMembers />
			</div>

			<div className='team-container bg-zinc-100'>
				<div className='advisory-panel flex flex-col justify-center bg-zinc-100'>
					{/* <h4>People</h4>
    <div className='managing-director'>
      <h5>Managing Director</h5>
      <div className='advisory-row'>
        <h6 className='text-start'>Deep Shikha</h6>
        <div className='img-container float-end ms-3 mb-3'>
          <img src={DeepShikha} alt='...' className='rounded-circle zoom-img normal-image' />
        </div>
        <p><span className='bold-name'></span> </p>
      </div>
    </div>
	<br /><br /> */}
					<p className='font-roboto text-5xl'><b>Advisory Team</b></p>
					<AdvisoryTeam />
				</div>
			</div>

		{/* About us section end */}

			<br />
			<br />
			<br />
			<br />
			<Footer />
		</div>
	
  )
};

export default Home;

