import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
// import './FeatureSlider.css';
import CCTVImg from '../../Media/Images/Slide_CCTV.jpeg';
import PostFactoImg from '../../Media/Images/post-facto-feature-img.jpeg';
import Sensitive from '../../Media/Images/sensitive.jpg';
import People from '../../Media/Images/people.jpg';
import LadyWithWatch from '../../Media/Images/lady-with-watch-croam-img.jpg';

import RecordReport from '../../Media/Images/record-and-report-img.jpg';
import LocationAware from '../../Media/Images/locationAware.png';
import Riskometer from '../../Media/Images/riskometer_new_img.jpg';

const FeatureSlider = () => {
    //to change the interval after which the image slides, just set the interval={time in miliseconds} in the carousel prop.
    //the default is interval={5000} ,i.e., 5 seconds.

    return (
        <>
            <div className='w-full lg:px-16 bg-zinc-100'>
                {/* No More Post-Facto */}
                <div className='flex items-center justify-center lg:justify-start m-3 mt-10 lg:h-[5rem]'>
                    <span className='feature-scroll-point text-xl lg:text-5xl'>
                        <b>No More Post-Facto</b>
                    </span>
                </div>
                <Carousel indicators={true} controls={true} pause={'hover'} touch={true} fade>
                    <Carousel.Item>
                        <div className='flex-nowrap '>
                            <div className='w-full h-[45vw] lg:h-[41vw] relative flex justify-center'>
                                <img className='w-full h-full object-cover' src={PostFactoImg} alt='First slide' />
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='flex-nowrap'>
                            <div className='w-full h-[45vw] lg:h-[41vw] relative flex justify-center'>
                                <img className='w-full h-full object-cover' src={RecordReport} alt='Fourth slide' />
                            </div>
                        </div>
                    </Carousel.Item>
                </Carousel>

                {/* Express Reporting - Any Time, Any Where */}
                <div className='flex items-center justify-center lg:justify-start m-3 lg:h-[5rem]'>
                    <span className='text-xl lg:text-5xl'>
                        <b>Express Reporting - Any Time, Any Where</b>
                    </span>
                </div>
                    <Carousel indicators={true} controls={true} data-bs-theme='dark' pause={'hover'} touch={true} fade>
                        <Carousel.Item>
                            <div className='flex-nowrap'>
                                <div className='w-full h-[45vw] relative flex justify-center'>
                                    <img className='w-full h-full object-cover' src={LadyWithWatch} alt='First slide' />
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className='flex-nowrap'>
                                <div className='w-full h-[45vw] relative flex justify-center'>
                                    <img className='w-full h-full object-cover' src={CCTVImg} alt='Fourth slide' />
                                </div>
                            </div>
                        </Carousel.Item>
                    </Carousel>

                {/* Location Aware, Scene Aware */}
                <div className='flex items-center justify-center lg:justify-start m-3 lg:h-[5rem]'>
                    <span className='text-xl lg:text-5xl'>
                        <b> Location Aware, Scene Aware</b>
                    </span>
                </div>

                    <Carousel indicators={true} controls={true} variant='dark' pause={'hover'} touch={true} fade>
                        <Carousel.Item>
                            <div className='flex-nowrap'>
                                <div className='w-full relative h-[50vw] flex justify-center'>
                                    <img className='w-full h-full object-cover' src={LocationAware} alt='First slide' />
                                </div>
                            </div>
                        </Carousel.Item>

                        <Carousel.Item>
                            <div className='flex-nowrap'>
                                <div className='w-full relative h-[50vw] flex justify-center'>
                                    <img className='w-full h-full object-cover' src={Riskometer} alt='Fourth slide' />
                                </div>
                            </div>
                        </Carousel.Item>
                    </Carousel>

                    {/* People Centric */}
                <div className='flex items-center justify-center lg:justify-start m-3 lg:h-[5rem]'>
                    <span className='text-xl lg:text-5xl'>
                        <b>People Centric</b>
                    </span>
                </div>
                    <Carousel indicators={true} controls={true} variant='dark' pause={'hover'} touch={true} fade>
                        <Carousel.Item>
                            <div className='flex-nowrap'>
                                <div className='w-full h-[35vw] relative flex justify-center'>
                                    <img className='w-full h-full object-cover' src={Sensitive} alt='First slide' />
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className='flex-nowrap'>
                                <div className='w-full h-[35vw] relative flex justify-center'>
                                    <img className='w-full h-full object-cover' src={People} alt='Fourth slide' />
                                </div>
                            </div>
                        </Carousel.Item>
                    </Carousel>
                </div>
        </>
    );
};

export default FeatureSlider;