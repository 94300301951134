import React, { useEffect } from 'react';
import AllPosts from '../../compos/AllPosts/AllPosts';
import { LoginProps } from '../Login/Login';
import { User } from '../../compos/SinglePostHelper/SinglePostHelper';
import Header from '../../compos/Header/Header';
import './News.css'
import { Navigate, useNavigate } from 'react-router-dom';
import MyFeed from '../../compos/AllPosts/MyFeed';
import { useUser } from '../../UserContext';

function News() {
	const {user, set_user} = useUser();
	if (user == null) {
		return (
			<div>
				<Navigate to='/login' />
			</div>
		)
	}
	else{
		return (
			<div className='flex flex-col relative bg-zinc-100 h-full'>
				<div className="hidden lg:block">
					<Header  /> {/* This will hide the header on screens smaller than "sm" */}
				</div>
				<MyFeed />
			</div>
		);
	}
}

export default News;
