// Global queue for all image requests across components
class GlobalImageCacheService {
    private static instance: GlobalImageCacheService;
    private cache: Map<string, string>;
    private requestQueue: Array<{
        mediaUrl: string,
        resolve: (value: string | null) => void,
        priority: number
    }>;
    private inFlightRequests: Map<string, Promise<string | null>>;
    private isProcessingQueue: boolean;
    private requestsInLastMinute: number;
    private lastRequestTime: number;
    private maxRequestsPerMinute: number;
    private retryDelayMs: number;
    private processingInterval: NodeJS.Timeout | null;
    private lastCacheUpdate: number;

    private constructor() {
        this.cache = new Map();
        this.requestQueue = [];
        this.inFlightRequests = new Map();
        this.isProcessingQueue = false;
        this.requestsInLastMinute = 0;
        this.lastRequestTime = Date.now();
        this.lastCacheUpdate = Date.now();
        this.maxRequestsPerMinute = 20;
        this.retryDelayMs = 2000;
        this.processingInterval = null;
        this.loadCacheFromStorage();
        this.startQueueProcessor();
    }

    public static getInstance(): GlobalImageCacheService {
        if (!GlobalImageCacheService.instance) {
            GlobalImageCacheService.instance = new GlobalImageCacheService();
        }
        return GlobalImageCacheService.instance;
    }

    private loadCacheFromStorage(): void {
        try {
            // Try to load from IndexedDB first (more persistent)
            const localCache = localStorage.getItem('globalImagePersistentCache');
            const sessionCache = sessionStorage.getItem('globalImageCache');
            const cacheTimestamp = localStorage.getItem('imageCacheTimestamp');
            
            // Check if cache is still valid (24 hours)
            const now = Date.now();
            const cacheAge = cacheTimestamp ? now - parseInt(cacheTimestamp) : Infinity;
            const isCacheValid = cacheAge < 24 * 60 * 60 * 1000; // 24 hours

            if (sessionCache && isCacheValid) {
                const cache = JSON.parse(sessionCache);
                Object.entries(cache).forEach(([key, value]) => {
                    this.cache.set(key, value as string);
                });
            } else if (localCache && isCacheValid) {
                const cache = JSON.parse(localCache);
                Object.entries(cache).forEach(([key, value]) => {
                    this.cache.set(key, value as string);
                });
                // Sync to sessionStorage for faster access
                sessionStorage.setItem('globalImageCache', localCache);
            }
        } catch (error) {
            console.error('Error loading cache:', error);
        }
    }

    private persistCache(): void {
        if (Date.now() - this.lastCacheUpdate < 1000) return; // Throttle cache updates

        const cacheObj: Record<string, string> = {};
        this.cache.forEach((value, key) => {
            cacheObj[key] = value;
        });

        try {
            const cacheString = JSON.stringify(cacheObj);
            localStorage.setItem('globalImagePersistentCache', cacheString);
            sessionStorage.setItem('globalImageCache', cacheString);
            localStorage.setItem('imageCacheTimestamp', Date.now().toString());
            this.lastCacheUpdate = Date.now();
        } catch (error) {
            console.error('Error persisting cache:', error);
        }
    }

    private startQueueProcessor(): void {
        if (this.processingInterval) {
            clearInterval(this.processingInterval);
        }

        this.processingInterval = setInterval(() => {
            this.processNextInQueue();
        }, 200);
    }

    private async processNextInQueue(): Promise<void> {
        if (this.isProcessingQueue || this.requestQueue.length === 0) return;

        this.isProcessingQueue = true;

        try {
            const now = Date.now();
            if (now - this.lastRequestTime >= 60000) {
                this.requestsInLastMinute = 0;
                this.lastRequestTime = now;
            }

            if (this.requestsInLastMinute >= this.maxRequestsPerMinute) {
                this.isProcessingQueue = false;
                return;
            }

            // Process requests in batches
            const batch = this.requestQueue.splice(0, 5);
            const uniqueRequests = new Map<string, { resolve: (value: string | null) => void, priority: number }>();
            
            // Deduplicate requests in the batch
            batch.forEach(request => {
                const existing = uniqueRequests.get(request.mediaUrl);
                if (!existing || request.priority > existing.priority) {
                    uniqueRequests.set(request.mediaUrl, { resolve: request.resolve, priority: request.priority });
                }
            });

            // Convert Map entries to array to avoid iteration issues
            const requests = Array.from(uniqueRequests.entries());
            
            for (const [mediaUrl, { resolve }] of requests) {
                try {
                    const response = await fetch(`https://backend.b-reporter.com/api/media/${mediaUrl}`, {
                        credentials: 'include'
                    });

                    if (response.status === 429) {
                        this.requestQueue.unshift({ mediaUrl, resolve, priority: -1 });
                        await new Promise(r => setTimeout(r, this.retryDelayMs));
                        continue;
                    }

                    if (!response.ok) throw new Error('Failed to load image');

                    const blob = await response.blob();
                    const imageUrl = URL.createObjectURL(blob);
                    this.cache.set(mediaUrl, imageUrl);
                    this.persistCache();
                    resolve(imageUrl);
                    this.requestsInLastMinute++;
                    this.lastRequestTime = now;

                    await new Promise(r => setTimeout(r, 100));
                } catch (error) {
                    console.error('Error loading image:', error);
                    resolve(null);
                }
            }
        } finally {
            this.isProcessingQueue = false;
        }
    }

    public get(key: string): string | undefined {
        return this.cache.get(key);
    }

    public async loadImage(mediaUrl: string, priority: number = 0): Promise<string | null> {
        const cachedImage = this.cache.get(mediaUrl);
        if (cachedImage) return cachedImage;

        const inFlightRequest = this.inFlightRequests.get(mediaUrl);
        if (inFlightRequest) return inFlightRequest;

        const requestPromise = new Promise<string | null>((resolve) => {
            this.requestQueue.push({ mediaUrl, resolve, priority });
        });

        this.inFlightRequests.set(mediaUrl, requestPromise);
        requestPromise.finally(() => {
            this.inFlightRequests.delete(mediaUrl);
        });

        return requestPromise;
    }

    public clearCache(): void {
        this.cache.clear();
        sessionStorage.removeItem('globalImageCache');
        localStorage.removeItem('globalImagePersistentCache');
        localStorage.removeItem('imageCacheTimestamp');
    }
}

export const imageCacheService = GlobalImageCacheService.getInstance(); 